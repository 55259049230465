import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
import { store } from '~/pages/heineken_template/_private/store';
import { stockDayTradeAvgPrice } from '~/trades/indicators/defaults/stockDayTradeAvgPrice';
import { stock_dealer } from '~/trades/indicators/futuresai/stock_chips/stock_dealer';
import { stock_foreigner } from '~/trades/indicators/futuresai/stock_chips/stock_foreigner';
import { stock_trust } from '~/trades/indicators/futuresai/stock_chips/stock_trust';
import { lung_dc } from '~/trades/indicators/lung88988/lung_dc';
import { lung_power } from '~/trades/indicators/lung88988/lung_power';
import { lung88988_strategy } from '~/trades/indicators/lung88988/lung_strategy';
import { signal } from '~/trades/indicators/lung88988/signal';
import { volume } from '~/trades/indicators/lung88988/volume';
import { lung88988Stock_initStrategies } from './lung88988Stock_initStrategies';
import { lung88988Stock_initStyling } from './lung88988Stock_initStyling';
import { Lung88988Stock_Sidepane1 } from './lung88988Stock_SidePane1';
import { Lung88988Stock_Sidepane2 } from './lung88988Stock_SidePane2';
import { Lung88988Stock_Topbar } from './lung88988Stock_Topbar';
import { Styleds } from './styleds';
import { lung88988_strategy2 } from '~/trades/indicators/lung88988/lung_strategy2';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const lung88988Stock_init = (templateProps) => {
    lung88988Stock_initStyling(templateProps);
    lung88988Stock_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/lung88988/loginView.jpg'] }]}/>);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    store.charting.indicatorsPreloaded = [
        lung_dc,
        lung88988_strategy,
        lung88988_strategy2,
        signal,
        stockDayTradeAvgPrice,
        volume,
        lung_power,
        stock_foreigner,
        stock_trust,
        stock_dealer,
    ];
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: '1D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.Charting = Styleds.Charting;
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../lung88988/chartingTheme-stock.css',
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Lung88988Stock_Topbar;
    templateProps.layout.Drawer1 = Lung88988Stock_Sidepane1;
    templateProps.layout.Drawer2 = Lung88988Stock_Sidepane2;
    templateProps.hooks.add(useSignalrStart2_0);
};
